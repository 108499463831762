@import url("https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono|Roboto+Slab&display=swap");

/* --mdc-theme-primary
--mdc-theme-secondary
--mdc-theme-background
--mdc-theme-surface
--mdc-theme-on-primary
--mdc-theme-on-secondary
--mdc-theme-on-surface
--mdc-theme-text-<TEXT_STYLE>-on-light
--mdc-theme-text-<TEXT_STYLE>-on-dark */

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  /* padding: 60px; */
  /* background: #f8f8f8; */
}
/*
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Slab", sans-serif;
  font-weight: 400;
}
*/
.mono {
  font-family: "Roboto Mono", sans-serif;
}
/*
.navbar {
  height: 40px;
  padding: 0 20px;
  background: white;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.navbar .nav-item {
  display: inline-block;
  padding: 10px;
}
.navbar .nav-item.active {
  background: #ccc;
}

.project h1 {
  margin-bottom: 0.5em;
}
*/
.bar {
  background: #67b930;
}
.bar.bar--under,
.bar.bar--over {
  background: #ff1844;
}

/* .bar:active,
.bar:focus */
.bar__selected {
  outline: none;
  box-shadow: 0 0 0 2px black;
}

.bar.bar--resizable {
  cursor: move;
}

.bar .drag {
  opacity: 0;
  cursor: ew-resize;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15px;
  opacity: 0;
}

.bar .drag--start {
  left: 0;
  border-left: 4px solid rgba(0, 0, 0, 0.5);
}

.bar .drag--end {
  right: 0;
  border-right: 4px solid rgba(0, 0, 0, 0.5);
}

.bar:hover .drag {
  opacity: 1;
}

.bar .tick {
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  width: 8px;
}
.bar .tick.tick-clickable {
  cursor: col-resize;
}

.bar .tick:after {
  position: absolute;
  top: 0;
  bottom: 0;
  content: "";
  width: 0px;
  left: 50%;
  transform: translateX(-50%);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.bar.bar--splitable .tick:hover:after {
  border: 0;
  width: 2px;
  background: rgba(0, 0, 0, 1);
}

.bar.bar--adder {
  opacity: 0;
}

.not-dragging .bar.bar--adder:hover {
  opacity: 0.5;
}

.bar .bar__start-date {
  position: absolute;
  top: -1.6em;
  left: 0.2em;
  font-size: 0.8em;
}

.bar .bar__end-date {
  position: absolute;
  bottom: -1.6em;
  right: 0;
  font-size: 0.8em;
}

.panel {
  max-width: 980px;
  margin: 0 auto;
  background: white;
  /* box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.04); */
  padding: 100px 0 40px 0;
}
.panel .mdc-card {
  padding: 0 0;
  margin-bottom: 40px;
}
.panel .mdc-card h2 {
  padding: 0 16px 16px 16px;
  margin-bottom: 0;
  border-bottom: 1px solid var(--mdc-theme-secondary);
}

.mdc-fab {
  position: fixed !important;
  bottom: 32px;
  right: 32px;
}

.header-with-overflow {
  display: flex;
}

.header-with-overflow .header-with-overflow__header {
  width: 300px;
}

.header-with-overflow .header-with-overflow__context {
  flex: 1;
  overflow-x: auto;
  overflow-y: visible;
}

.tasks {
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgb(0, 0, 0, 0.25);
  margin: 20px 0 40px 0;
}

.tasks .tasks__start-date {
  position: absolute;
  bottom: -1.6em;
  left: 0.2em;
  font-size: 0.8em;
}

.tasks .tasks__red {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 3px;
  background: #ff1844;
}

.tasks .tasks__max {
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 1px solid grey;
  background: lightgrey;
  z-index: 2;
}

.person {
  font-size: 1em;
  width: 100%;
  border-bottom: 1px solid rgb(0, 0, 0, 0.25);
  margin: 20px 0 40px 0;
  position: relative;
  font-family: "Roboto Mono", sans-serif;
}

.person .person__remove {
  display: none;
  right: 5px;
}

.person:hover .person__remove {
  position: absolute;
  display: inline;
}

.person .person__text {
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
}

.person .person__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.4em;
  font-size: 1em;
}

.person .person__role {
  font-size: 0.6em;
  padding: 3px 10px;
  background: var(--mdc-theme-secondary);
  color: var(--mdc-theme-text-primary-on-dark);
  border-radius: 2px;
  width: 3em;
  text-align: center;
  display: inline-block;
  margin-right: 1em;
}

.person .person__cost {
  font-size: 0.8em;
  display: inline-block;
}

.zoom-in,
.zoom-out {
  position: fixed;
  top: 15px;
}

.zoom-in {
  top: 80px;
}
.zoom-out {
  top: 128px;
}
